var lazyLoadInstance = new LazyLoad({
    // Your custom settings go here
});

import "@master/css";
import useHeader from "./partial/Header";
import useScrollbar from "./partial/Scrollbar";
import useSubnav from "./partial/Subnav";
import useSlider from "./partial/Slider";
import useDetail from "./partial/Detail";
import "./partial/Streaming";

window.initMasterCSS();

const { newsSliderInit } = useSlider();

jQuery(function () {
    newsSliderInit();

    $(".subnav-cta li a").on("click", function (e) {
        if (!$(this).hasClass("link")) {
            e.preventDefault();
            scrollToElement($(this).attr("href"));
        }
    });
});

try {
    var prm = Sys.WebForms.PageRequestManager.getInstance();
    prm.add_endRequest(function () {
        $(".has-vertical-scrollbar").each(function (index, element) {
            const verticalScrollbar = new SimpleBar(element, {
                classNames: {
                    contentWrapper: "simplebar-content-wrapper dragscroll",
                },
            });
        });
        dragscroll.reset();
    });
    prm.add_pageLoaded(function (a) {
        console.log(a);
    });
} catch (error) {}

var scrollToElement = function (el, ms) {
    var speed = ms ? ms : 2000;
    let height = $(el).offset().top - $("header").outerHeight();
    if ($(".scrollnav").length) {
        height =
            $(el).offset().top -
            $("header").outerHeight() -
            $(".scrollnav").outerHeight();
    }
    $("html,body").animate(
        {
            scrollTop: height,
        },
        speed
    );
};
