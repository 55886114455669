try {
    if ($(".main-menu-wrapper").length > 0) {
        const searchWrapperMapping = new MappingListener({
            selector: ".search-wrapper",
            mobileWrapper: ".menu-mobile-body",
            mobileMethod: "appendTo",
            desktopWrapper: ".top-left",
            desktopMethod: "insertAfter",
            breakpoint: 1025,
        }).watch();

        const mainMenuMapping = new MappingListener({
            selector: ".main-menu-wrapper",
            mobileWrapper: ".menu-mobile-body",
            mobileMethod: "appendTo",
            desktopWrapper: ".menu-toggle",
            desktopMethod: "insertBefore",
            breakpoint: 1025,
        }).watch();
    }

    const topMenuMapping = new MappingListener({
        selector: ".toplink",
        mobileWrapper: ".menu-mobile-body",
        mobileMethod: "appendTo",
        desktopWrapper: ".top-right",
        desktopMethod: "appendTo",
        breakpoint: 1025,
    }).watch();

    const menuMapping = new MappingListener({
        selector: ".header-menu",
        mobileWrapper: ".menu-mobile-body",
        mobileMethod: "appendTo",
        desktopWrapper: ".header-bottom",
        desktopMethod: "insertAfter",
        breakpoint: 1025,
    }).watch();

    const subnavCtaMapping = new MappingListener({
        selector: ".subnav-cta-wrap",
        mobileWrapper: ".section-trending .container",
        mobileMethod: "appendTo",
        desktopWrapper: ".news-navigation .col-lg-9",
        desktopMethod: "appendTo",
        breakpoint: 1025,
    }).watch();
} catch (error) {}

$(".close-menu").on("click", function () {
    $(".menu-mobile").removeClass("show");
    $("body").removeClass("overflow-hidden");
});

$(".menu-toggle").on("click", function () {
    $(".menu-mobile").addClass("show");
    $("body").addClass("overflow-hidden");
});

$(".btn-subMobile").on("click", function () {
    $(this).siblings(".sub").slideToggle();
    $(this).parents(".has-sub").siblings().find(".sub").slideUp();
});

if ($(window).width() > 1024) {
    $(".header-menu").sticky({
        topSpacing: 0,
        zIndex: 50,
    });
} else {
    var $header = $(".header"); // Your header selector
    var previousScroll = $header.outerHeight();

    // Create placeholder div
    var $placeholder = $("<div>", { class: "header-placeholder" }).insertBefore(
        $header
    );

    // Set the height of the placeholder to match the header
    $placeholder.height($header.outerHeight());

    $(window).scroll(function () {
        var currentScroll = $(this).scrollTop();
        if (currentScroll > $header.outerHeight()) {
            $header.addClass("is-sticky");
        } else {
            $header.remove("is-sticky");
        }
        $header.toggleClass("scrolling-down", currentScroll > previousScroll);
        previousScroll =
            currentScroll > $header.outerHeight()
                ? currentScroll
                : $header.outerHeight();
        // console.log(previousScroll);
    });
}

function formatDate(date) {
    var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hours = "" + d.getHours(),
        minutes = "" + d.getMinutes(),
        seconds = "" + d.getSeconds();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hours.length < 2) hours = "0" + hours;
    if (minutes.length < 2) minutes = "0" + minutes;
    if (seconds.length < 2) seconds = "0" + seconds;

    return (
        [day, month, year].join("/") +
        ", " +
        [hours, minutes, seconds].join(":")
    );
}

function displayDateTime() {
    var date = new Date();
    var weekday = date.toLocaleString("vi-VN", { weekday: "long" });
    var formattedDate = formatDate(date);
    $(".today").html(`${weekday}, ${formattedDate}`);
}

setInterval(displayDateTime, 1000);

export default function useHeader() {
    return {};
}
