const tiktokSlider = new Swiper(".tiktok-slider .swiper-container", {
    slidesPerView: "auto",
    spaceBetween: 32,
    pagination: {
        el: ".tiktok-slider .swiper-pagination",
        clickable: true,
    },
});
const tvShowSlider = new Swiper(".tv-show-slider .swiper-container", {
    slidesPerView: 1,
    spaceBetween: 32,
    pagination: {
        el: ".tv-show-slider .swiper-pagination",
        clickable: true,
    },
    navigation: {
        prevEl: ".tv-show-slider .swiper-prev",
        nextEl: ".tv-show-slider .swiper-next",
    },
    autoplay: {
        delay: 5000,
    },
    speed: 1300,
});

const newsSliderInit = () => {
    $(".news-slider").each(function (index) {
        var $this = $(this);
        if ($(this).data("slides") !== undefined) {
            var initSlide = $(this).data("slides");
        } else {
            var initSlide = 4;
        }
        $this.addClass("news-slider-" + index);
        $this.find(".swiper-prev").addClass("news-slider-prev-" + index);
        $this.find(".swiper-next").addClass("news-slider-next-" + index);
        $this.find(".swiper-pagination").addClass("news-pagination-" + index);

        var newsSlider = new Swiper(
            ".news-slider-" + index + " .swiper-container",
            {
                slidesPerView: initSlide,
                spaceBetween: 24,
                navigation: {
                    prevEl: ".news-slider-prev-" + index,
                    nextEl: ".news-slider-next-" + index,
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 3.5,
                        spaceBetween: 16,
                        freeMode: true,
                    },
                    768: {
                        slidesPerView: 2.5,
                        spaceBetween: 16,
                        freeMode: true,
                    },
                    576: {
                        slidesPerView: 1.5,
                        spaceBetween: 16,
                        freeMode: true,
                    },
                },
            }
        );
    });
};

export default function useSlider() {
    return {
        newsSliderInit,
    };
}
