var siteRoot = "";
$("body").on("click", ".online-streaming-channel a", function (e) {
    var obj = $(this);
    $(".online-streaming-channel li").removeClass("active");
    obj.parent().addClass("active");

    var urladd = siteRoot + "/HTVModule/Services/htvService.aspx";
    var channelid = obj.attr("data-id");
    var channelcode = obj.attr("data-code");

    var data = {
        method: "GetScheduleList",
        channelid: channelid,
        template: "AjaxSchedules.xslt",
        channelcode: channelcode,
    };

    $.ajax({
        cache: false,
        url: urladd,
        data: data,
        type: "post",
        success: function (response) {
            if (response.success) {
                $(".ajaxscheduleresponse").html("");
                $(".ajaxscheduleresponse").html(
                    $(response.data).find(".ajaxscheduleresponse").html()
                );
                $(".filter-select-item select option").removeAttr("selected");
                $(".filter-select-item select")
                    .find(`option[value=${channelid}]`)
                    .attr("selected", "selected");
                if (response.chanelUrl)
                    $(".online-streaming-iframe iframe").attr(
                        "src",
                        response.chanelUrl
                    );
            }
        },
        complete: function () {},
    });

    return false;
});
