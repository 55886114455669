if ($(window).width() > 1024) {
    $(".has-horizontal-scrollbar").each(function (index, element) {
        const horizontalScrollbar = new SimpleBar(element, {
            classNames: {
                contentWrapper: "simplebar-content-wrapper dragscroll",
            },
        });
    });
}
$(".has-vertical-scrollbar").each(function (index, element) {
    const verticalScrollbar = new SimpleBar(element, {
        classNames: {
            contentWrapper: "simplebar-content-wrapper dragscroll",
        },
    });
});
dragscroll.reset();

$(".menu .simplebar-content-wrapper").scrollTo(".active", 500);

export default function useScrollbar() {
    return {};
}
