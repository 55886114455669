$(".news-detail-append-right").append($(".news-detail-48h"));
$(".news-detail-append-left").append($(".news-detail-latest"));
$(".news-detail-comment").append($(".commentpanel"));

if ($("body").hasClass("news-detail-page")) {
    $(".promo-banners-append").append($(".promo-banners-module"));
} else {
    $(".promo-banners-append").append($(".promo-banners-module"));
}

// $('.article-content iframe[src*="youtube"]').parent().fitVids();

export default function useDetail() {
    return {};
}
