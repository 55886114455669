if ($(window).width() > 1024) {
} else {
    $(".subnav-mobile-toggle").on("click", function () {
        $(this).find("span").toggleClass("fa-chevron-down fa-chevron-up");
        $(this).next().slideToggle();
    });
}

export default function useSubnav() {
    return {};
}
